//
// card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  background: $card-background-color;
  border-color: $card-border-color;
  li {
    background-color: $light-gray-gg;
  }
}

.card-drop {
  font-size: 20px;
  line-height: 0;
  color: inherit;
}

.card-widgets {
  float: right;
  height: 16px;
  > a {
    color: inherit;
    font-size: 18px;
    display: inline-block;
    line-height: 1;

    &.collapsed {
      i {
        &:before {
          content: '\F415';
        }
      }
    }
  }
}

// Card title / Card Header
.card-title,
.card-header {
  margin-top: 0;
}

.card-body {
  border-radius: 15px;
  background: rgb(158, 171, 181);
}

//Card disable loading (Custom Cards)
.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: $card-border-radius;
  background: rgba($white, 0.8);
  cursor: progress;

  .card-portlets-loader {
    position: absolute;
    left: 50%;
    top: calc(50% - 1rem);
    margin-left: -($grid-gutter-width / 2);
    margin-top: -($grid-gutter-width / 2);
  }
}

@keyframes loaderAnimate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(220deg);
  }
}

@keyframes loaderAnimate2 {
  0% {
    box-shadow: inset $dark 0 0 0 12px;
    transform: rotate(-140deg);
  }
  50% {
    box-shadow: inset $dark 0 0 0 2px;
  }
  100% {
    box-shadow: inset $dark 0 0 0 12px;
    transform: rotate(140deg);
  }
}

// Custom card box
.card-box {
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  padding: $card-spacer-x;
  margin-bottom: $grid-gutter-width;
  @include border-radius($card-border-radius);
}

.header-title {
  font-size: 1rem;
  margin: 0 0 5px 0;
}

.sub-header {
  font-size: 0.875rem;
  margin-bottom: $grid-gutter-width;
  color: $gray-600;
}
