.tree__container,
#from_tree__container__step__card__first,
.tree__container__branch {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tree__container__step__card {
  text-align: center;
  margin: 32px 20px;
}

.tree__container__step__card .tree__container__step__card__p {
  padding: 10px;
  box-shadow: 0 0 4px 1px rgba(121, 121, 121, 0.3);
  border-radius: 4px;
  text-align: center;
  width: 150px;
  display: inline-block;
  margin: 0 !important;
}

#tree__svg-container {
  z-index: -1;
  position: absolute;
  height: 100%;
}

.tree__container__step {
  flex: 1 1 0px;
  width: auto;
  padding: 0;
}

.tree__container__step__card {
  position: relative;
}

.tree__container__step__card::before {
  content: var(--top-subbadge-content);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% + 2px));
  padding: 0 8px;
  background-color: var(
    --top-subbadge-background-color,
    rgba(121, 121, 121, 0.3)
  );
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--top-subbadge-color);
}

.tree__container__step__card__p {
  position: relative;
  cursor: pointer;
  transition: transform 0.2s ease;
}
.tree__container__step__card__p::before {
  content: var(--top-badge-content);
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(75%, 50%);
  padding: 0 8px;
  background-color: var(--top-badge-background-color, rgba(121, 121, 121, 0.3));
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--top-badge-color);
}
.tree__container__step__card__p::after {
  content: var(--bottom-badge-content);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% - 2px));
  padding: 0 8px;
  background-color: var(
    --bottom-badge-background-color,
    rgba(121, 121, 121, 0.3)
  );
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--bottom-badge-color);
  white-space: nowrap;
}
