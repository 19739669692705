.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;

  .filter-list-container {
    background: white;
    color: black;
    box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 1);
    border-radius: 5px;
    position: absolute;
    top: 35px;
    left: 0;
    padding: 10px 10px;
    z-index: 9999;

    &--header-container {
      display: flex;
      justify-content: space-between;
      gap: 100px;
      min-width: 400px;
      align-items: center;
    }

    &--header-title {
      font-weight: bold;
      font-size: 20px;
    }

    &--clear-button {
      padding: 5px 10px;
      width: 100px;
      background: white;
      border: none;
    }

    &--add-button {
      display: flex;
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }

  .filter-button {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: white;
    border: none;
    color: #000;
    border-radius: 5px;

    &--active {
      background: #4caf50;
      color: #fff;
    }
  }
}
