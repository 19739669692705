.gantt-chart-page {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}

.gantt-chart-sidebar {
  &-container {
    padding-top: 55px;
    border-right: 1px solid #9a9a9a;
    border-bottom: 1px solid #9a9a9a;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  &-item {
    display: block;
    box-shadow: 0px 1px 0px 0px #e6e6e6;
    line-height: 55.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      border-top: 1px solid #9a9a9a;
    }
    &:last-child {
      border-bottom: '1px solid #9a9a9a';
    }
  }
}

.gantt-chart-slider {
  display: flex;
  align-items: center;
  padding: 15px 0;

  & .left-date {
    width: 15%;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
  }

  & .right-date {
    width: 15%;
    padding-left: 10px;
  }
}

.gantt-chart-top-bar {
  display: flex;
  position: relative;

  & .filter-button {
    width: 10%;
    padding: 0 20px 0 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    top: 100%;
    height: 55.5px;
  }

  & .chart-options-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0;

    & .container {
      display: flex;
    }

    & .moved-left {
      margin-left: 10px;
    }

    & .centered-vertically {
      display: flex;
      align-items: center;
    }
  }

  & .filter-list {
    position: absolute;
    left: 20px;
    top: 125px;
    z-index: 999;
    background-color: #afafaf;
    padding: 20px 25px;
    display: flex;

    & .column {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    & .reset-button {
      margin-left: 15px;
      padding: 10px;
    }

    & .item {
      padding: 15px 10px;
      min-width: 150px;
      margin: 5px 0;
      border-width: 2px;
      border-style: solid;
    }
  }
}

.gantt-chart-modal-footer {
  display: flex;
  justify-content: space-between;
}

.gantt-chart-timeline-container {
  background: white;
  border-left: 1px solid #9a9a9a;
  border-bottom: 1px solid #9a9a9a;
  display: flex;
  align-items: center;
}
