.forecasting-page {
  &-white-button {
    background: white;
    border: none;
  }

  &__table {
    &--header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--sorting {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
  }

  &__filters {
    &--container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  &__filter-item {
    &--container {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 20px 10px 0 10px;
    }

    &--row {
      display: flex;
      width: 100%;
      gap: 5px;
    }

    &--clear-button {
      background: white;
      border: none;
    }
  }
}
