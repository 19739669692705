.preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(221, 221, 221);
}

.status {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px;
}
