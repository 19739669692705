//
// forms.scss
//

// Form-control light
.form-control-light {
  background-color: $gray-100;
  border-color: $gray-100;
  border-radius: 16px;
}

// Form elements (Color and Range)
input.form-control[type='color'],
input.form-control[type='range'] {
  min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
  &:focus {
    box-shadow: none !important;
  }
}

// Input
input {
  &:focus {
    outline: none;
  }
}
