.checkbox-container {
  display: flex;

  input {
    margin-right: 8px;
  }

  label {
    margin: 0;
  }

  input,
  label {
    vertical-align: middle;
    width: max-content;
  }
}
