//
// x-editable.scss
//

.editable-clear-x {
  background: url('/assets/images/plugins/clear.png') center center no-repeat;
}

.editableform-loading {
  background: url('/assets/images/plugins/loading.gif') center center no-repeat;
}

.editable-checklist {
  label {
    display: block;
  }
}
