.select-container {
  .select {
    &__control {
      color: #6c757d;
      border: 1px solid #ced4da;
      border-radius: 15px;
    }

    &__menu {
      &-list {
        div {
          cursor: pointer;
        }
      }
    }
  }
}
