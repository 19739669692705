//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
  // background-color: $dark-gray-gg;
}

body {
  overflow-x: hidden;
  // background: $dark-gray-gg;
  color: rgba(0, 0, 0, 0.575);
  ul {
    border-radius: 12px;
  }
  p {
    color: rgba(0, 0, 0, 0.664);
  }
}
