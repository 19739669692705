/*
Template Name: Minton - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 4.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

//Fonts
@import 'custom/fonts/fonts';

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/mixins.scss';

// Structure
@import 'custom/structure/general';
@import 'custom/structure/horizontal-nav';
@import 'custom/structure/topbar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
@import 'custom/structure/right-sidebar';

// Components
@import 'custom/components/helper';
@import 'custom/components/social';
@import 'custom/components/widgets';
@import 'custom/components/custom-checkbox';
@import 'custom/components/custom-radio';
@import 'custom/components/ribbons';
@import 'custom/components/print';
@import 'custom/components/preloader';

// Components - Common
@import 'custom/components/common/alert-bar';

// Components - UI
@import 'custom/components/ui/checkbox';
@import 'custom/components/ui/filter';
@import 'custom/components/ui/select';

// Plugins
@import 'custom/plugins/waves';
@import 'custom/plugins/slimscroll';
@import 'custom/plugins/toaster';
@import 'custom/plugins/sweetalert';
@import 'custom/plugins/nestable-list';
@import 'custom/plugins/jstree';
@import 'custom/plugins/ion-rangeslider';
@import 'custom/plugins/bootstrap-tagsinput';
@import 'custom/plugins/bootstrap-select';
@import 'custom/plugins/multiple-select';
@import 'custom/plugins/select2';
@import 'custom/plugins/clockpicker';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/daterange';
@import 'custom/plugins/parsley';
@import 'custom/plugins/summernote';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/quilljs-editor';
@import 'custom/plugins/dropzone';
@import 'custom/plugins/x-editable';
@import 'custom/plugins/datatable';
@import 'custom/plugins/tablesaw';
@import 'custom/plugins/responsive-table';
@import 'custom/plugins/footables';
@import 'custom/plugins/flot';
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/morris';
@import 'custom/plugins/c3';
@import 'custom/plugins/chartist';
@import 'custom/plugins/sparkline-chart';
@import 'custom/plugins/google-maps';
@import 'custom/plugins/vector-maps';
@import 'custom/plugins/mapeal-maps';
@import 'custom/plugins/calendar';
@import 'custom/plugins/hopscotch';

// Pages
@import 'custom/pages/components-demo';
@import 'custom/pages/logout';
@import 'custom/pages/error';
@import 'custom/pages/email';
@import 'custom/pages/taskboard';
@import 'custom/pages/coming-soon';
@import 'custom/pages/timeline';
@import 'custom/pages/faq';
@import 'custom/pages/pricing';
@import 'custom/pages/gallery';
@import 'custom/pages/gantt-chart';
@import 'custom/pages/bar-chart';
@import 'custom/pages/forecasting';

.mobile-only {
  display: none;
}

@media screen and (max-width: 1000px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: unset;
  }
}

@media screen and (min-width: 1000px) {
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e2e2e2;
  opacity: 1;
}

.buttonProgress {
  color: $light-green-gg;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.desktopFlexRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 1000px) {
    flex-direction: row;
  }
}

.roomsGrid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: center;

  @media screen and (min-width: 1000px) {
    grid-auto-flow: row;
    grid-template-columns: var(--traysColumns);
    grid-template-rows: var(--traysRows);
  }
}

label[for='select-color'] {
  margin: 0px 5px 0px 0px;
  width: fit-content;
}

@media screen and (max-width: 600px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}
