.bar-chart-page {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: #fff;
    overflow: visible;
    z-index: 9999;
  }

  .arrow_box {
    position: absolute;
    background-color: #000;
    padding: 20px;
  }

  .apexcharts-tooltip.apexcharts-theme-light,
  .arrow_box {
    width: 316px;
  }
}
