//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  > a {
    color: #343a40;
    &:hover {
      color: #343a40;
    }
  }
  + .breadcrumb-item {
    &::before {
      font-family: 'Material Design Icons';
    }
  }
}
